import { toast } from 'react-toastify';
import api from './index';
import Cookies from 'universal-cookie';
// import { toast } from 'react-toastify';

export const loginUser = async formData => {
  const cookie = new Cookies();

  return new Promise(async (resolve, reject) => {
    try {
      // console.log("FormData:", formData);
      const res = await api.post('/auth/sign-in', formData);
      // console.log("Response Data:", res.data);
      // setItemToLocalStorage('userInfo', JSON.stringify(res.data));
      // setItemToLocalStorage('userId', JSON.stringify(res.data.data.userId));
      // setItemToLocalStorage('authToken', JSON.stringify(res.data.data.token));

      const user = {
        firstName: 'Ajaezo',
        lastName: 'Kingsley',
        email: 'ajaezokingsley@gmail.com',
        affId: 1254044,
        role: 'vendor',
        token:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyYjc0ZjJlMDU3MDQ4ZDA4NDJhM2U2MyIsInJvbGUiOiJ2ZW5kb3IiLCJjb3VudHJ5Ijoic3Rha2VjdXQiLCJpYXQiOjE2NTg4MTEwOTgsImV4cCI6MTY1ODg5NzQ5OH0.n_xe8bD5NEHHglyvXMvbmyA2T5zONLKwK5abgIYUZ9g',
        notification: [
          {
            status: true,
            Message: 'Please Update your Profile before you continue',
            link: 'https://app.stakecut.com/profile',
          },
        ],
      };

      cookie.set('authToken', res.data.data.token, {
        path: '/',
        // maxAge: 3600, // Expires after 1hr
        sameSite: true,
      });
      cookie.set('notification', res?.data?.data, {
        path: '/',
        // maxAge: 3600, // Expires after 1hr
        sameSite: true,
      });

      cookie.set('userId', res.data.data.userId, {
        path: '/',
        // maxAge: 3600, // Expires after 1hr
        sameSite: true,
      });

      cookie.set('role', res.data.data.role, {
        path: '/',
        // maxAge: 3600, // Expires after 1hr
        sameSite: true,
      });
        // Set accountType in cookie or wherever suitable
        // cookie.set('accountType', res.data.data.accountType, {
        //   path: '/',
        //   // maxAge: 3600, // Expires after 1hr
        //   sameSite: true,
        // });

      cookie.set(
        'fullName',
        res.data.data.firstName + ' ' + res.data.data.lastName,
        {
          path: '/',
          // maxAge: 3600, // Expires after 1hr
          sameSite: true,
        }
      );

      // cookie.set('firstName', res.data.data.firstName, {
      //   path: '/',
      //   maxAge: 3600, // Expires after 1hr
      //   sameSite: true,
      // });
      cookie.set('accountType', res.data.data.accountType, { path: '/', sameSite: true });

      // Log accountType to console
      // console.log('Account Type:', res.data.data.accountType);

      cookie.set('affId', res.data.data.affId, {
        path: '/',
        // maxAge: 3600, // Expires after 1hr
        sameSite: true,
      });
      // Add a request interceptor

      resolve(res);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401) {
          cookie.remove('authToken', { path: '/' });
          cookie.remove('user', { path: '/' });
          cookie.remove('userId', { path: '/' });
          toast.error('Your session has expired. Please login again.');
          window.location.href = '/auth/sign-in';
        } else if (status === 400) {
          toast.error(data.message || 'Bad Request: Invalid request data.');
        } else if (status === 403) {
          toast.error('Forbidden: You do not have permission to access this resource.');
        } else if (status === 404) {
          toast.error('Not Found: The requested product could not be found.');
        } else if (status === 500) {
          toast.error('Server Error: Please try again later.');
        } else {
          toast.error(data.message || 'An error occurred while fetching the product details.');
        }
      } else if (error.request) {
        toast.error('Network Error: Please check your internet connection and try again.');
      } else {
        toast.error('Error: ' + error.message);
      }
      reject(error);
    }
  });
};
